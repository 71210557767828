import {
  CloseSvg,
  color,
  Flex,
  spacing,
  Spinner,
  Text,
  useIsMobileView,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import useAuth from 'providers/hooks/useAuth';
import useNsi from 'providers/hooks/useNsi';
import useProfile from 'providers/hooks/useProfile';
import React, { useCallback } from 'react';
import { Analytics, Events } from 'services/analytics';
import NextImage from 'src/components/image/NextImage';
import usePaymentCheckoutModal from 'src/components/paywall/paywall-checkout-modal/usePaymentCheckoutModal';
import { UserSubscriptionType } from 'types/enums';
import { getRemWidthFromColumns } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';

interface SpecialOfferInviteBannerProps {
  sale: {
    couponCode: string;
    iconUrl?: string;
    style: Record<string, string>;
    title: string;
    mobileCta: string;
    url: string;
  };
  onCloseCb: () => void;
}

const SpecialOfferBanner: React.FC<SpecialOfferInviteBannerProps> = ({
  sale,
  onCloseCb,
}) => {
  const isMobileView = useIsMobileView();
  const router = useRouter();
  const { profile } = useProfile();
  const { isLoggedIn } = useAuth();
  const { couponCode, iconUrl, mobileCta, style, title, url } = sale;

  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onCloseCb();
  };

  const icon = !!iconUrl ? (
    <NextImage
      wrapperStyle={{
        width: isMobileView ? 36 : 48,
        height: isMobileView ? 36 : 48,
        pointerEvents: 'none',
      }}
      objectFit="contain"
      imageUrl={iconUrl}
    />
  ) : null;

  const {
    checkoutModal,
    onOpenCheckoutModal,
    subscriptionIntentRequestStatus,
  } = usePaymentCheckoutModal({
    subType: '1 YEAR',
    promoCode: couponCode,
    analyticsLocation: 'global-bottom-banner',
  });

  const { nsiDispatch } = useNsi();

  const onStartPriceClick = useCallback(() => {
    if (isLoggedIn) {
      onOpenCheckoutModal();
    } else {
      nsiDispatch({
        type: 'openAuth',
        payload: {
          type: 'sign-up',
          shouldSkipOnboarding: true,
          discountCode: couponCode,
          onAuthSuccess: _profile => {
            isMobileView && router.push(url);
          },
        },
      });
    }
  }, [profile?.subscriptionType, nsiDispatch, isLoggedIn, onOpenCheckoutModal]);

  return (
    <>
      {checkoutModal}
      {!isLoggedIn ||
      profile?.subscriptionType !== UserSubscriptionType.Premium ? (
        <Flex
          left="50%"
          transform="translateX(-50%)"
          width={{
            base: 'calc(100% - 2rem)',
            md: getRemWidthFromColumns(6),
            lg: getRemWidthFromColumns(10),
          }}
          onClick={() => {
            Analytics.logEvent({
              eventName: Events.payments.clickOfferBanner,
              properties: { location: router.asPath },
              platforms: ['amplitude'],
            });
            onStartPriceClick();
          }}
          cursor="pointer"
          boxShadow="0px 6px 16px rgba(19, 19, 19, 0.8); 0px 8px 4px rgba(19, 19, 19, 0.4);"
          position="fixed"
          justifyContent="space-between"
          borderRadius="1rem"
          zIndex={1000}
          bottom={spacing.M.rem}
          backgroundColor={style.backgroundColor}
          alignItems="center"
          p={spacing.M.rem}
          m={{ base: spacing.toRem(4), md: spacing.M.rem }}
          color={style.textColor ?? color.light.textInverted}
          border={`2px solid ${style.borderColor}`}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {!isMobileView && (
            <Flex gridGap={8}>
              {icon}
              <Flex flexDirection="column">
                <Text
                  fontSize={{ base: '1rem', md: '1rem' }}
                  opacity={0.9}
                  fontWeight={500}
                >
                  <span style={{ fontWeight: 700, color: style.accentColor }}>
                    {title}
                  </span>
                </Text>
                <Text
                  fontSize={{ base: '1.5rem', md: '1.5rem' }}
                  fontWeight={700}
                >
                  45% OFF | 1-Year Pro Subscription
                </Text>
              </Flex>
            </Flex>
          )}

          <Flex
            w={isMobileView ? '100%' : 'auto'}
            alignItems="center"
            gridGap={spacing.XS.rem}
          >
            {isMobileView && icon}
            {subscriptionIntentRequestStatus === 'loading' ? (
              <Spinner />
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                padding={8}
                paddingX={12}
                borderRadius={16}
                backgroundColor="transparent"
                flex={1}
              >
                <Text
                  color={style.textColor}
                  fontSize={{ base: '1.25rem', md: '1.25rem' }}
                  fontWeight={600}
                >
                  {isMobileView ? mobileCta : actionStrings.claimOffer}
                </Text>
              </Flex>
            )}
            <span
              style={{
                color: color.dark.text,
                opacity: 0.4,
                marginLeft: spacing.XXS.rem,
                marginRight: spacing.XXS.rem,
              }}
            >
              |
            </span>
            <CloseSvg onClick={onClose} />
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

export default SpecialOfferBanner;
