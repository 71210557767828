import jsCookie from 'js-cookie';
import useAuth from 'providers/hooks/useAuth';
import useProfile from 'providers/hooks/useProfile';
import React, { useEffect, useRef, useState } from 'react';
import { UserSubscriptionType } from 'types/enums';
import SpecialOfferBanner from './SpecialOfferBanner';

const SALES = {
  easter: {
    couponCode: 'EASTER2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_bunny.svg',
    style: {
      backgroundColor: '#EE6F68',
      borderColor: '#278E7C',
      accentColor: '#FFE7E5',
      textColor: '#FFFFFF',
    },
    url: '/easter',
    title: 'Easter is Here! Grab the sales!',
    mobileCta: 'View the Easter Offer',
  },
  'mothers-day': {
    couponCode: 'MOTHER2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_mother.svg',
    style: {
      backgroundColor: '#FCECE8',
      borderColor: '#EE717B',
      accentColor: '#EE717B',
      textColor: '#D25E68',
    },
    url: '/mothers-day',
    title: 'Celebrating all mothers around the world...',
    mobileCta: "View our Mother's Day Offer",
  },
  'fathers-day': {
    couponCode: 'FATHER2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_father.svg',
    style: {
      backgroundColor: '#E1F3FB',
      borderColor: '#186D9C',
      accentColor: '#2C83B3',
      textColor: '#186D9C',
    },
    url: '/fathers-day',
    title: 'Celebrating all fathers around the world...',
    mobileCta: "View our Father's Day Offer",
  },
  'independence-day': {
    couponCode: 'INDEPENDENCE2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_eagle.svg',
    style: {
      backgroundColor: '#16385D',
      borderColor: '#CF1715',
      accentColor: '#D5E5ED',
      textColor: '#FFFFFF',
    },
    url: '/independence-day',
    title: 'In the name of independence...',
    mobileCta: 'View the Independence Day Offer',
  },
  autumn: {
    couponCode: 'INDEPENDENCE2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_autumn_leaves.svg',
    style: {
      backgroundColor: '#D05034',
      borderColor: '#89441E',
      accentColor: '#FFFFFF',
      textColor: '#FFFFFF',
    },
    url: '/autumn-special',
    title: 'Autumn is here! Grab the sales!',
    mobileCta: 'View our Autumn Sale Offer',
  },
  halloween: {
    couponCode: 'HALLOWEEN2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_halloween.svg',
    style: {
      backgroundColor: '#FEF0C7',
      borderColor: '#F79009',
      accentColor: '#F79009',
      textColor: '#3B3F44',
    },
    url: '/halloween',
    title: 'Boo! Prices so low they will scare you!',
    mobileCta: 'View our Halloween Offer',
  },
  'black-friday': {
    couponCode: 'BLACKFRIDAY2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_black_friday.svg',
    style: {
      backgroundColor: '#000000',
      borderColor: '#A43832',
      accentColor: '#A43832',
      textColor: '#FFFFFF',
    },
    url: '/blackfriday24',
    title: 'Black Friday is here!',
    mobileCta: 'View our Black Friday Offer',
  },
  christmas: {
    couponCode: 'HOLIDAYS2449',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_xmas.svg',
    style: {
      backgroundColor: '#D92D20',
      borderColor: '#FFFFFF',
      accentColor: '#FFFFFF',
      textColor: '#FFFFFF',
    },
    url: '/xmas',
    title: 'Deck the halls with microlearning!',
    mobileCta: 'View our Christmas Offer',
  },
  'new-year': {
    couponCode: 'NEWYEARS2549',
    iconUrl:
      'https://static.deepstash.com/illustrations/paywall_price_icon_nye.svg',
    style: {
      backgroundColor: '#1E1640',
      borderColor: '#FFA600',
      accentColor: '#FFA600',
      textColor: '#FFFFFF',
    },
    url: '/new-year-offer',
    title: 'Kickstart 2025 with a strong microlearning habit!',
    mobileCta: 'View our New Year Offer',
  },
};

const SALE_TIMELINE: Array<{ sale: any; start: Date; end: Date }> = [
  {
    sale: SALES.easter,
    start: new Date('2024-04-01T00:00:00'),
    end: new Date('2024-04-15T00:00:00'),
  },
  {
    sale: SALES['mothers-day'],
    start: new Date('2024-05-05T00:00:00'),
    end: new Date('2024-05-10T00:00:00'),
  },
  {
    sale: SALES['fathers-day'],
    start: new Date('2024-06-10T00:00:00'),
    end: new Date('2024-06-24T00:00:00'),
  },
  {
    sale: SALES['independence-day'],
    start: new Date('2024-07-01T00:00:00'),
    end: new Date('2024-07-08T00:00:00'),
  },
  {
    sale: SALES['autumn'],
    start: new Date('2024-09-05T00:00:00'),
    end: new Date('2024-09-11T00:00:00'),
  },
  {
    sale: SALES['halloween'],
    start: new Date('2024-10-28T00:00:00'),
    end: new Date('2024-11-07T00:00:00'),
  },
  {
    sale: SALES['black-friday'],
    start: new Date('2024-11-22T00:00:00'),
    end: new Date('2024-12-04T00:00:00'),
  },
  {
    sale: SALES['christmas'],
    start: new Date('2024-12-20T00:00:00'),
    end: new Date('2024-12-27T00:00:00'),
  },
  {
    sale: SALES['new-year'],
    start: new Date('2024-12-30T00:00:00'),
    end: new Date('2025-01-06T00:00:00'),
  },
];

const getCurrentSale = (currentDate: Date) => {
  let currentSale = undefined;
  for (let i = 0; i < SALE_TIMELINE.length; i++) {
    const nextSale = SALE_TIMELINE[i];
    if (nextSale.start <= currentDate && nextSale.end > currentDate) {
      currentSale = nextSale.sale;
      break;
    }
  }
  return currentSale;
};

const SpecialOfferBannerWrapper: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { profile } = useProfile();
  const { isLoggedIn } = useAuth();

  const { current: currentSale } = useRef(getCurrentSale(new Date()));

  useEffect(() => {
    if (!jsCookie.get('special_offer_banner_seen')) setIsVisible(true);
  }, [jsCookie.get('special_offer_banner_seen')]);

  const onCloseCb = () => {
    // Only hide it for a day is the user is logged in
    isLoggedIn &&
      jsCookie.set('special_offer_banner_seen', 'true', { expires: 1 / 24 });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  // Do not show the banner if:
  // the user is not logged in
  // OR the user is Pro
  // OR there is no active sale

  return currentSale &&
    (!isLoggedIn ||
      // profile?.promotionalSale ||
      profile?.subscriptionType !== UserSubscriptionType.Premium) ? (
    <SpecialOfferBanner onCloseCb={onCloseCb} sale={currentSale} />
  ) : null;
};

export default SpecialOfferBannerWrapper;
